import React, { useState, useEffect } from "react";

import { Modal } from "react-responsive-modal";

import "./WrapperModalVideo.scss";

export function VideoPlaybutton() {
  return (
    <svg
      height="117"
      viewBox="0 0 117 117"
      width="117"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <g>
        <g fill="none" opacity=".7" stroke="#fff" strokeWidth="6">
          <circle cx="58.5" cy="58.5" r="58.5" stroke="none" />
          <circle cx="58.5" cy="58.5" fill="none" r="55.5" />
        </g>
        <path
          d="m32 0 32 56h-64z"
          fill="#fff"
          opacity=".7"
          transform="matrix(0 1 -1 0 94 27)"
        />
      </g>
    </svg>
  );
}

export default function WrapperModalVideo({
  videoId = false,
  showButton = false,
  children = false,
  channel = "youtube",
  autoplay = true,
}) {
  if (!videoId) {
    return <>{children}</>;
  }

  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };
  const onCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      onCloseModal();
    };
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customVideo",
        }}
      >
        <div className="WrapperModalVideo">
          {channel === "youtube" && (
            <iframe
              width="640"
              height="450"
              src={`https://www.youtube.com/embed/${videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
          {channel === "vimeo" && (
            <iframe
              width="640"
              height="450"
              src={`https://www.youtube.com/embed/${videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </Modal>

      <div className="WrapperModalVideoClick">
        <button
          className="naked-button player-picture"
          onClick={onOpenModal}
          type="button"
          aria-label="Play video"
        >
          {showButton && (
            <div className="abs-center">
              <VideoPlaybutton />
            </div>
          )}
          {children}
        </button>
      </div>
    </>
  );
}
