import React from "react";

import Main from "components/layout/Main";
import Heading from "components/Heading";

import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import Picture from "components/Picture";
import ContentGrid from "components/ContentGrid";

import GridCards from "components/GridCards";
import CardBenefit from "components/CardBenefit";

import MediaContainer from "components/MediaContainer";
import WrapperModalVideo from "components/WrapperModalVideo";

import PointerParent from "components/PointerParent";

import backgroundCover from "assets/images/header_skew/mission.jpg";

import MissionDriven from "assets/images/mission/vid_give_power.jpg";
import MissionDrivenWebp from "assets/images/mission/vid_give_power.jpg";

import ScrollToTop from "components/ScrollToTop";

const impacts = [
  "20,000 jobs on the Loanpal platform",
  "Over 31 million metric tons of CO2 offset by Loanpal systems <small class='subindex'>1</small>",
  "$5.8 billion offset in utility bills for over 160,000 Loanpal homeowners <small class='subindex'>1</small>",
  "Improved the lives of more than 500,000 people in the developing regions <small class='subindex'>2</small>",
];

const points = ["headingslim1", "headingslim2", "headingslim3"];

export default function IndexPage() {
  return (
    <Main page={"mission"} pagetitle="Mission">
      <HeaderSkew
        details={false}
        title="Our mission is to connect a world in which everyone can live sustainably."
        background={backgroundCover}
        appearance="small"
      />
      <PointerParent points={points}>
        <Block layer="1" name="Direct Pay" guides={1} appearance="regular">
          <ContentGrid appearance="faq">
            <div className="MissionHeadingWrapper">
              <Heading type="h2" appearance="h4" id={points[0]}>
                We are a digital conduit connecting the world’s growing
                sustainability marketplaces. We provide frictionless,
                point-of-sale technology for countless mission-driven
                professionals and millions of people who seek to live more
                sustainably.
              </Heading>
            </div>
          </ContentGrid>
        </Block>

        <Block layer="1" guides={1} appearance="gray-skew">
          <ContentGrid appearance="centered">
            <>
              <Heading type="h2" appearance="h3--padded-bottom" id={points[1]}>
                Our impact
              </Heading>

              <GridCards modifier={["4-small", "fullwidth"]}>
                {impacts.map((impact) => (
                  <CardBenefit
                    title={impact}
                    modifier={["orange"]}
                    key={impact}
                  />
                ))}
              </GridCards>

              <div className="cardDisclaimer">
                <div>
                  <p className="subindex">
                    <small className="subindex">1</small> Offset of currently
                    installed systems projected over 25 years
                  </p>{" "}
                  <p className="subindex">
                    <small className="subindex">2</small> In partnership with
                    the GivePower Foundation
                  </p>
                </div>
              </div>
            </>
          </ContentGrid>
        </Block>

        <Block
          layer="1"
          name="Direct Pay"
          guides={1}
          appearance="mission-bottom"
        >
          <ContentGrid appearance="centered">
            <>
              <Heading type="h2" appearance="h3--padded-bottom" id={points[2]}>
                We are mission-driven
              </Heading>

              <MediaContainer modifier={["full-center"]}>
                <WrapperModalVideo videoId="-8RAu3gQAgk" showButton={false}>
                  <Picture
                    src={MissionDriven}
                    webp={MissionDrivenWebp}
                    width="655"
                    height="430"
                    alt="We are mission-driven"
                  />
                </WrapperModalVideo>
              </MediaContainer>
              <ScrollToTop modifier={["slim-bottom"]} guides={0} />
            </>
          </ContentGrid>
        </Block>
        {/* */}
      </PointerParent>
    </Main>
  );
}
